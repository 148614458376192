import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography
} from "@material-ui/core";
import React, { useContext } from "react";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "../Chat/Customisation.module.css";
import {
  Check,
  Close,
  DeleteForever,
  DoneAll,
  LocalHospital,
  PhonelinkLock,
  SmsFailed,
  Update,
  Snooze
} from "@material-ui/icons";
import clsx from "clsx";

const DialogInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { appConfig, setAppConfig } = useContext(AppContext);

  const handleCloseDialogInfo = () => {
    setAppConfig(oAppConfig => {
      return {
        ...oAppConfig,
        viewInfoDialog: false
      };
    });
  };

  return (
    <Dialog
      open={appConfig.viewInfoDialog}
      onClose={handleCloseDialogInfo}
      id={appConfig.darkTheme ? styles["proeduca-messenger-dark"] : styles["proeduca-messenger"]}
    >
      <DialogTitle className={classes.dialogTitle} id="dialog-title">
        {t("information")}
      </DialogTitle>
      <DialogContent className={appConfig.darkTheme ? classes.dialogDark : classes.dialog}>
        <Accordion className={appConfig.darkTheme ? classes.accordionDark : classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4-content"
            id="panel4-header"
          >
            <Typography variant="h6">{t("titleResize")}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Typography variant="body1">{t("infoResize")}</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className={appConfig.darkTheme ? classes.accordionDark : classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            <Typography variant="h6">{t("titleInfoMarkdown")}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Typography variant="body1">{t("infoMarkdown")}</Typography>
            <br />
            <Typography variant="body1">{t("infoMarkdown2")}</Typography>
            <br />
            <div className={classes.separacionMarkdown}>
              <Typography
                className={
                  appConfig.darkTheme ? classes.markdownSecctionDark : classes.markdownSecction
                }
                variant="body1"
              >
                {t("infoMarkdown3")}
              </Typography>
              <Typography
                className={
                  appConfig.darkTheme ? classes.markdownSecctionDark : classes.markdownSecction
                }
                variant="body1"
              >
                {t("infoMarkdown3v2")}
              </Typography>
            </div>
            <br />
            <Typography variant="body1">{t("infoMarkdown4")}</Typography>
            <br />
            <div className={classes.separacionMarkdown}>
              <Typography
                className={
                  appConfig.darkTheme ? classes.markdownSecctionDark : classes.markdownSecction
                }
                variant="body1"
              >
                {t("infoMarkdown5")}
              </Typography>
              <Typography
                className={
                  appConfig.darkTheme ? classes.markdownSecctionDark : classes.markdownSecction
                }
                variant="body1"
              >
                {t("infoMarkdown5v2")}
              </Typography>
            </div>
            <br />
            <Typography variant="body1">{t("infoMarkdown6")}</Typography>
            <br />
            <Typography
              className={
                appConfig.darkTheme ? classes.markdownSecctionDark : classes.markdownSecction
              }
              variant="body1"
            >
              {t("infoMarkdown7")}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className={appConfig.darkTheme ? classes.accordionDark : classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography variant="h6">{t("titleInfoScheduleMessages")}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Typography variant="body1">{t("infoScheduleMessages")}</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className={appConfig.darkTheme ? classes.accordionDark : classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6">{t("titleInfoAudios")}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Typography variant="body1">{t("infoAudios")}</Typography>
            <br />
            <Typography variant="body1">{t("infoAudios2")}</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className={appConfig.darkTheme ? classes.accordionDark : classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6">{t("titleInfoMessageStatus")}</Typography>
          </AccordionSummary>
          <AccordionDetails className={clsx(appConfig.darkTheme ? classes.accordionDetailsDark : classes.accordionDetails)}>
            <Typography variant="body1">
              <table>
                <thead>
                  <tr>
                    <th style={{ textAlign: "left", width: "45px" }}>
                      {t("infoMessageStatusCell1")}
                    </th>
                    <th style={{ textAlign: "left" }}>{t("infoMessageStatusCell2")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Update color="primary" />
                    </td>
                    <td>{t("infoMessageStatusRow1")}</td>
                  </tr>
                  <tr>
                    <td>
                      <Check color="primary" />
                    </td>
                    <td>{t("infoMessageStatusRow2")}</td>
                  </tr>
                  <tr>
                    <td>
                      <DoneAll color="primary" />
                    </td>
                    <td>{t("infoMessageStatusRow3")}</td>
                  </tr>
                  <tr>
                    <td>
                      <DoneAll className={classes.read} />
                    </td>
                    <td>{t("infoMessageStatusRow4")}</td>
                  </tr>
                  <tr>
                    <td>
                      <Close color="primary" />
                    </td>
                    <td>{t("infoMessageStatusRow5")}</td>
                  </tr>
                  <tr>
                    <td>
                      <DeleteForever color="primary" />
                    </td>
                    <td>{t("infoMessageStatusRow6")}</td>
                  </tr>
                  <tr>
                    <td>
                      <PhonelinkLock color="primary" />
                    </td>
                    <td>{t("infoMessageStatusRow7")}</td>
                  </tr>
                  <tr>
                    <td>
                      <LocalHospital color="primary" />
                    </td>
                    <td>{t("infoMessageStatusRow8")}</td>
                  </tr>
                  <tr>
                    <td>
                      <Snooze color="primary" />
                    </td>
                    <td>{t("infoMessageStatusRow9")}</td>
                  </tr>
                </tbody>
              </table>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </DialogContent>

      <DialogActions
        className={appConfig.darkTheme ? classes.dialogActionsDark : classes.dialogActions}
      >
        <Button onClick={handleCloseDialogInfo} color="primary" className={classes.actionButton}>
          <Typography variant="caption" color="primary">
            {t("close")}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogInfo;

const useStyles = makeStyles(() => ({
  actionButton: {
    marginRight: 20
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  dialogTitle: {
    backgroundColor: "#065d54",
    color: "#fff"
  },
  dialog: {
    padding: "20px"
  },
  dialogDark: {
    backgroundColor: "#3e3e42",
    color: "#fff"
  },
  markdownExplanation: {
    display: "flex",
    gap: "2%"
  },
  markdownSecction: {
    backgroundColor: "#f1f1f1",
    padding: "2%",
    width: "45%"
  },
  markdownSecctionDark: {
    color: "#FFFFFF",
    backgroundColor: "#242426",
    padding: "2%",
    width: "45%"
  },
  separacionMarkdown: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  dialogActions: {
    borderTop: "1px solid #bdbdbd",
    padding: "0.8rem 0"
  },
  dialogActionsDark: {
    backgroundColor: "#3e3e42",
    borderTop: "1px solid #595959",
    color: "#fff",
    padding: "0.8rem 0"
  },
  accordion: {
    marginTop: 10
  },
  accordionDark: {
    marginTop: 10,
    backgroundColor: "#2d2d30",
    color: "#FFFFFF"
  },
  accordionDetails: {
    flexDirection: "column"
  },
  accordionDetailsDark: {
    flexDirection: "column",
    color: "#FFFFFF"
  },
  read: {
    color: "#42a5f5"
  }
}));
