import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import AppContext from "./AppContext";
import Chat from "./components/Chat/Chat";
import Helper from "./utils/Helper";
import ApiCalls from "./utils/APIRequests";
import Login from "./Login";
import { createTheme, ThemeProvider } from "@material-ui/core";
import is_electron from "is-electron";
import packageInfo from "../package.json";


let version = packageInfo.version;

const App = ({
  appProps: {
    sPhone,
    sAssessorName,
    sAssessorLastName,
    sAssessorEmail,
    sSource,
    sClientName,
    sClientLastName,
    sClientEmail,
    sCountry,
    sCourseId,
    sCourseName,
    bFilterSource,
    sLanguage,
    bDeveloper,
    bShowUserList,
    bAlwaysRefresh,
    bMinimized,
    sCompany,
    sUnit,
    nWidth,
    nHeight,
    nSize,
    bDarkTheme,
    aDefaultMessages,
    aoSources,
    sDisplayName,
    bGroup,
    sEncryptionKey,
    sVersion,
    sHost,
    bUseWs,
    bShowCloseButton,
    aoUserTags,
    aousetags,
    aoExpandedTagsMenu,
    bVerbose,
    oNotifications,
    onLoadError,
    defaultSelectedPhone,
    sDefaultCompanyMessagesfilters
  }
}) => {
  const [client, setClient] = useState({
    name: sClientName || "",
    lastName: sClientLastName || "",
    compoundName: sClientLastName ? `${sClientName ?? ''} ${sClientLastName ?? ''}` : sClientName,
    email: sClientEmail || "",
    country: sCountry || "",
    courseId: sCourseId,
    courseName: sCourseName,
    phone: sPhone,
    displayname: sDisplayName,
    group: bGroup,
    key: `${sPhone}:${sSource}:${sCompany}`
  });

  const [assessor, setAssessor] = useState({
    name: sAssessorName,
    lastName: sAssessorLastName,
    compoundName: sAssessorLastName ? `${sAssessorName} ${sAssessorLastName}` : sAssessorName,
    email: sAssessorEmail
  });

  const [delegates, setDelegates] = useState([]);

  const [appConfig, setAppConfig] = useState({
    filter: typeof bFilterSource === "undefined" || bFilterSource === null ? true : bFilterSource,
    size: nSize ? nSize : 14,
    width: nWidth ? nWidth : "850px",
    height: nHeight ? nHeight : "650px",
    language: sLanguage || "",
    minimized: bMinimized ? bMinimized : false,
    company: sCompany || "",
    unit: sUnit || "",
    source: sSource || Helper.constants.defaultSource,
    sourceFilter: sSource || Helper.constants.defaultSource,
    sources: [],
    aoSources: aoSources || [],
    selectedIndex: "",
    selectedUserKey: "",
    myUser: {
      name: sAssessorName,
      lastName: sAssessorLastName,
      compoundName: sAssessorName ? `${sAssessorName} ${sAssessorLastName}` : sAssessorName,
      email: sAssessorEmail,
      unread: 0
    },
    clientConfig: {},
    default_messages: aDefaultMessages || [],
    isNotCallGetDelegates: false,
    openDialogGroupInfo: false,
    groupInfo: {},
    tokenWebFirebase: "",
    tokenElectron: "",
    loggedIn: false,
    darkTheme: bDarkTheme,
    scheduleScreen: false,
    globalSearchScreen: false,
    encryptionkey: sEncryptionKey ? sEncryptionKey : "",
    openDialogGlobalSearch: false,
    viewInfoDialog: false,
    version: sVersion ? sVersion : version,
    host: sHost,
    usews: bUseWs,
    showclose: bShowCloseButton || false,
    userTags: aoUserTags,
    usetags: aousetags,
    expandedTagsMenu: aoExpandedTagsMenu,
    verbose: bVerbose,
    systemNotifications: [],
    currentTabId: "",
    notifications: oNotifications,
    defaultCompanyMessagesfilters: sDefaultCompanyMessagesfilters
  });

  const [companyTags, setCompanyTags] = useState([]);

  const [controlConfig, setControlConfig] = useState({
    searchUserList: "",
    searchUserContact: "",
    searchMessage: "",
    isNewGroup: false,
    isUpdateGroup: false,
    selectUsersGroup: [],
    viewUserList: bShowUserList === undefined || bShowUserList === null ? true : bShowUserList,
    viewContactList: false,
    showLoaderUserList: true,
    showLoaderContactList: false,
    showLoaderMessage: false,
    tabSelection: "all",
    tabSelectedContactList: "customers",
    isElectron: false,
    openManageTagsDialog: false,
    selectedUserTags: {}
  });

  const debugApp = {
    developer: bDeveloper || false,
    alwaysRefresh: bAlwaysRefresh ? bAlwaysRefresh : false
  };

  const [needUpdateUserLayout, setNeedUpdateUserLayout] = useState(false);
  const [online, setOnline] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [contactAssessorList, setContactAssessorList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [newChatItem, setNewChatItem] = useState({});
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [searchMessages, setSearchMessages] = useState([]);
  const [messageSchedule, setMessageSchedule] = useState([]);
  const [messagesSchedule, setMessagesSchedule] = useState([]);
  const [twilioVideo, setTwilioVideo] = useState({
    openModal: false,
    url: "",
    roomName: "",
    roomToken: "",
    audioOnly: false,
    assesorEmail: "",
    clientEmail: "",
    uniq: "",
    labelAudio: "",
    isConnected: false
  });

  const [updateComponent, setUpdateComponent] = useState({
    appStartTimestamp: new Date().getTime(),
    isSendingMessage: false,
    isRecordingAudio: false,
  });

  const [lastWebComponentVersion, setLastWebComponentVersion] = useState({version: null});
  const [skipSaveAppConfig, setSkipSaveAppConfig] = useState(false);

  const [initNewMQTTWS, setInitNewMQTTWS ] = useState(false)
  const [MQTTConnectionsList, setMQTTConnectionsList ] = useState({
    connected: [],
    failed: [],
    to_start: [],
    lost: [],
    to_disconnect: []
  })
  const [stopDelegating, setStopDelegating ] = useState(false)
  const [selectedTag, setSelectedTag] = useState({key: null, tag: null});

  const [autoSelectConversation, setAutoSelectConversation] = useState({ulUser: null, clUser: null});
  // const [shouldSelectConversation, setShouldSelectConversation] = useState(false);
  const shouldSelectConversation = useRef(false);

  const [getMessagesByEmailCalled, setGetMessagesByEmailCalled] = useState(false);

  const [defaultMessages, setDefaultMessages] = useState([]);
  const [selectedDefaultMessage, setSelectedDefaultMessage] = useState(null);
  const [inputChatText, setInputChatText] = useState('');
  // const [defaultCompanyMessagesfilters, setDefaultCompanyMessagesfilters] = useState([]);


  // Array donde vamos a añadir los errores de toast de Chat.js
  const [errorMessageArr, setErrorMessageArr] = useState([]);
  const errorMessageThrown = useRef([]);

  const [initPhone, setInitPhone] = useState(defaultSelectedPhone);

  useEffect(()=>{setInitPhone(defaultSelectedPhone);}, [defaultSelectedPhone])

  useEffect(() => {
    setErrorMessageArr(onLoadError);
  }, [onLoadError]) 

  const userSelected = useCallback(
    (oUser, calledFromInit = false) => {
      localStorage.setItem("userKey", oUser.key);
      localStorage.setItem("userPhone", oUser.phone);
      localStorage.setItem("userCourse", oUser.course_id);
      shouldSelectConversation.current = false;
      if (userList?.length > 0) {
        if (calledFromInit) {
          let found = userList.find(item => item.key == oUser.key);
          if (typeof found != 'undefined') {
            // Poner la conversación seleccionada la primera y limpiar el array de conversaciones nuevas.
            setUserList(uList => {
              let _usersList = uList?.filter(item => 
                typeof item.isNewChat == 'undefined'
                && (found && typeof found.key != 'undefined' && item.key != found.key)
              )
              return [found, ..._usersList]
            });
          }
        } else {
          setUserList(uList => {
            let _usersList = uList?.filter(item => typeof item.isNewChat == 'undefined')
            return [..._usersList]
          });
        }
      }

      setNewChatItem({});
      setClient({
        name: oUser.client_name,
        lastName: oUser.client_lastname,
        compoundName: oUser.client_lastname
          ? `${oUser.client_name} ${oUser.client_lastname}`
          : oUser.client_name,
        email: oUser.client_email,
        country: oUser.country,
        courseId: oUser.course_id,
        courseName: oUser.course_name,
        phone: oUser.phone,
        group: oUser.group,
        displayname: oUser.displayname,
        groupid: oUser.groupid,
        active: oUser.active,
        company: oUser.company,
        source: oUser.source,
        unit: oUser.unit,
        key: oUser.key,
        track: oUser.track
      });

      setSkipSaveAppConfig(true);
      setAppConfig(appConfig => {
        return {
          ...appConfig,
          filter: oUser.filter,
          language: oUser.language,
          source: typeof appConfig.source == 'undefined' || appConfig.source.length == 0 ? oUser.source : appConfig.source,
          selectedIndex: oUser.phone,
          selectedUserKey: oUser.key
        };
      });
    },
    [setClient, setAssessor, setAppConfig, userList, setUserList, initPhone]
  );

  const newContactGroupSelected = useCallback(oGroup => {
    setNewChatItem({
      date_modified: oGroup.date_modified,
      displayname: oGroup.displayname,
      groupid: oGroup.groupid,
      source: oGroup.source
    });

    setClient({
      displayname: oGroup.displayname,
      group: true,
      groupid: oGroup.groupid,
      phone: oGroup.groupid,
    });

    setControlConfig(controlConfig => {
      return {
        ...controlConfig,
        viewContactList: false,
        viewUserList: true,
        showLoaderContactList: false
      };
    });
    setMessages([]);
  }, []);

  const newContactSelected = useCallback(
    oUser => {
      ApiCalls.validatePhoneAPI(
        oUser.phone,
        oUser.country,
        oUser.company,
        appConfig.unit,
        appConfig.host
      )
        .then(oResponse => {
          const company = (oUser.company?.length > 0 ? oUser.company : appConfig.company) || false;
          if (!company) {
            throw new Error(`No se puede iniciar la conversación con ${oUser.phone}. Asegúrate de que el componente se inicializa con el parámetro "company" o que el contacto con el que quieres hablar tiene la propiedad "company".`)
          }
          let conversationID = `${oUser.phone}:${oUser.source}:${company}`;
          setNewChatItem({
            name: oUser.client_name,
            lastName: oUser.client_lastname,
            compoundName: oUser.client_lastname
              ? `${oUser.client_name} ${oUser.client_lastname}`
              : oUser.client_name,
            email: oUser.client_email,
            country: oUser.country,
            courseId: oUser.course_id,
            courseName: oUser.course_name,
            phone: oUser.phone,
            company: oUser.company,
            source: oUser.source,
            unit: oUser.unit,
            key: conversationID,
            conversationid: conversationID,
          });

          localStorage.setItem("userKey", conversationID);
          localStorage.setItem("userPhone", oUser.phone);
          localStorage.setItem("userCourse", oUser.course_id);

          const _client = {
            ...oUser,
            name: oUser.client_name,
            lastName: oUser.client_lastname,
            compoundName: oUser.client_lastname
              ? `${oUser.client_name} ${oUser.client_lastname}`
              : oUser.client_name,
            email: oUser.client_email,
            country: oUser.country,
            courseId: oUser.course_id,
            courseName: oUser.course_name,
            phone: oUser.phone,
            group: oUser.group,
            displayname: oUser.displayname,
            company: oUser.company,
            source: oUser.source,
            unit: oUser.unit,
            key: typeof oUser.key == 'undefined' || oUser.key?.length <= 0 ? conversationID : oUser.key,
            track: typeof oUser.track == 'undefined' && oUser.track?.length > 0 ? oUser.track : ''
          };
          setClient(_client);
          setUserList(_userList => {
            let oClient = { ..._client, unread: 0, cansendattach: false }
            let found = _userList.find(item => item.key == _client.key);
            if (found?.length <= 0) {
              oClient.isNewChat = true
            }
            return [
              oClient, 
              ..._userList.filter(item => item.key != _client.key && typeof item.isNewChat == 'undefined')
            ]});
            
          setFilteredUserList(_filteredUserList => [
            ..._filteredUserList,
            { ..._client, unread: 0, cansendattach: false }
          ]);
          setSkipSaveAppConfig(true);
          setAppConfig(appConfig => {
            return {
              ...appConfig,
              filter: oUser.filter,
              language: oUser.language,
              source: typeof appConfig.source == 'undefined' || appConfig.source.length == 0 ? oUser.source : appConfig.source,
              selectedIndex: undefined,
              selectedUserKey: oUser.key
            };
          });
          setControlConfig(controlConfig => {
            return {
              ...controlConfig,
              viewContactList: false,
              viewUserList: true,
              showLoaderContactList: false,
              showLoaderMessage: false
            };
          });
          setMessages([]);
        })
        .catch(sError => {
          if (typeof errorMessageThrown.current[6] == 'undefined' || (typeof errorMessageThrown.current[6] != 'undefined' && errorMessageThrown.current[6] == false) ) {
            let errMsg = sError.message || 'No se puede iniciar una conversación con el usuario seleccionado por que el teléfono no es correcto.';
            setErrorMessageArr(errMsgArr => [
              errMsgArr,
              {
                key: "company",
                code: 6,
                message: errMsg,
                value: null
              }
            ]);
            errorMessageThrown.current[6] = true;
          }

          if (appConfig.verbose) {
            console.log(errMsg);
          }
        });
    },
    [setClient, setAssessor, setAppConfig, setControlConfig, setUserList, userList]
  );

  const saveSettings = useCallback(() => {
    if ([true, 'true', 1, '1'].indexOf(skipSaveAppConfig) < 0) {
      ApiCalls.saveUserSettings(assessor.email, appConfig)
        .then()
        .catch()
        .finally();
    }
    setSkipSaveAppConfig(false);
  }, [appConfig]);

  const getClientConfig = useCallback(() => {
    ApiCalls.getClientConfig(appConfig.host, appConfig.company, appConfig.sourceFilter)
      .then(response => response.json())
      .then(response => {
        setSkipSaveAppConfig(true)
        setAppConfig(oAppConfig => {
          return {
            ...oAppConfig,
            loggedIn: true,
            clientConfig: response,
            isNotCallGetDelegates: Helper.removeCallDelegates(response, oAppConfig.source)
          };
        });
      })
      .catch(() => {});
  }, [appConfig.sourceFilter, appConfig.source]);

  const getSystemNotifications = useCallback(() => {
    ApiCalls.getSystemNotifications(appConfig.host)
      .then(response => response.json())
      .then(response => {
        let futureNotifications = [];
        for(let idx in response.systemnotifications) {
          const notification = response.systemnotifications[idx];
          const notificationBeginDate = new Date(notification.dateBegin)
          if (notificationBeginDate >= new Date()) {
            futureNotifications.push(notification)
          }
        }

        setSkipSaveAppConfig(true)
        setAppConfig(appConfig => {
          return {
            ...appConfig,
            systemNotifications: futureNotifications,
          };
        });
      })
      .catch((e) => {});
  }, []);

  // Obtener las company tags en la carga del componente
  const getCompanyTags = useCallback(() => {
    ApiCalls.getCompanyTags(appConfig.host, appConfig.source, appConfig.company)
      .then(response => response.json())
      .then(response => {
        setCompanyTags(response);
      })
      .catch(() => {});
  }, []);

  const getDefaultMessages = useCallback(() => {
    const getUserConfigMessages = () => {
      const config_messages = [];
      if (aDefaultMessages?.length > 0) {
        aDefaultMessages.forEach(message => {
          if (typeof message.title != 'undefined' && message.title.length > 0 && typeof message.message != 'undefined' && message.message.length > 0) {
            config_messages.push({
              title: message.title,
              text: message.message,
              type: message.type
            });
          }
        });
      }
      return config_messages;
    }
    

    const getContent = (json) => {
      let contenidos = [];
      
      const searchContent = (nodo, parentLabels = []) => {
        if (typeof nodo.content !== 'undefined' && nodo.content && nodo.content.length > 0) {
          let messages = [];
          nodo.content.forEach(message => {
            messages.push({
              ...message,
              type: 'system_message',
              parents: [...parentLabels, nodo.label.toUpperCase() ?? '']
            });
          });
          contenidos.push(...messages);
        }

        if (typeof nodo.childs !== 'undefined' && nodo.childs) {
          if (Array.isArray(nodo.childs)) {
            nodo.childs.forEach(child => searchContent(child, [...parentLabels, nodo.label.toUpperCase() ?? '']));
          } else {
            Object.values(nodo.childs).forEach(child => searchContent(child, [...parentLabels, nodo.label.toUpperCase() ?? '']));
          }
        }
      }

      searchContent(json);
      return contenidos;
    }

    ApiCalls.getDefaultMessages(appConfig.host, appConfig.source, appConfig.company)
      .then(response => response.json())
      .then(response => {
        let contenidos = [];
        response.forEach(companyMessages => {
          contenidos = [...contenidos, ...getContent(companyMessages)];
        });
        const config_messages = getUserConfigMessages();
        // contenidos = getContent(response);
        setDefaultMessages(oMessages => [...oMessages, ...config_messages, ...contenidos]);
      })
      .catch(() => {
        const config_messages = getUserConfigMessages();
        setDefaultMessages(oMessages => [...oMessages, ...config_messages]);
      })
  }, []);

  useEffect(() => {
    getClientConfig();
    getSystemNotifications();
    getCompanyTags();
    getDefaultMessages();
    if (typeof sPhone != 'undefined' && sPhone?.length > 0) {
      shouldSelectConversation.current = true;
      // setShouldSelectConversation(true);
    }

    ApiCalls.getLastWebComponentVersion(appConfig.host)
      .then(response => response.json())
      .then(response => {
        setLastWebComponentVersion(response.version);
      })
      .catch(e => {})

    const intvl = setInterval(() => {
      ApiCalls.getLastWebComponentVersion(appConfig.host)
      .then(response => response.json())
      .then(response => {
        const componentVersionInServer = response.version;
        if (componentVersionInServer != version) {
          let reloadInterval = setInterval(() => {
            if (updateComponent.isSendingMessage == false && updateComponent.isRecordingAudio == false) {
              clearInterval(reloadInterval);
              window.location.reload();
            }
          }, 1000);
        }
      })
      .catch(e => {})
    }, 1000*60*60*12 ); // => 12h


    return () => {clearInterval(intvl);}
  }, []);


  const foundContact = useRef(false);
  useEffect(()=> {
    // Comentada la obligatoriedad de la lista de contactos para los usuarios sin lista que lo usan desde Mafalda
    if (shouldSelectConversation.current && getMessagesByEmailCalled /* && contactList.length > 0 */) {
      let min = [true, 'true', 1, '1'].indexOf(bMinimized) > -1;
      let show_userlist = [false, 'false', 0, '0'].indexOf(bShowUserList) > -1 ? false : true;
      // Primero intentar seleccionar un contacto por la lista de usuarios.
      if (userList.length > 0 && autoSelectConversation.ulUser == null) {
        let ulUser = userList.find(item => item.phone == sPhone);
        if ( typeof ulUser != 'undefined' && ulUser != null ) {
          foundContact.current = true;
          setAutoSelectConversation(autoSelConv => {return {
            ...autoSelConv,
            ulUser: ulUser
          }});
          shouldSelectConversation.current = false;
          selectConversation(sPhone, min, show_userlist, Helper.cleanPhone(initPhone) == Helper.cleanPhone(sPhone));
          setInitPhone(false);
        }
      }

      // si no lo encontramos por la lista de usuarios lo intentamos por la de contactos (Crear nueva conversación).
      if (autoSelectConversation.clUser == null && !foundContact.current) {
        let clUser = contactList.find(item => item.phone == sPhone);
        if ( typeof clUser != 'undefined' && clUser != null ) {
          foundContact.current = true;
          setAutoSelectConversation(autoSelConv => {return {
            ...autoSelConv,
            clUser: clUser
          }});
          shouldSelectConversation.current = false;
          selectConversation(sPhone, min, show_userlist, Helper.cleanPhone(initPhone) == Helper.cleanPhone(sPhone));
          setInitPhone(false);
        }
      }
      
      
      if (
        contactList.length == 0 // No tiene agenda de contactos
        && !foundContact.current
        && autoSelectConversation.ulUser == null
        && autoSelectConversation.clUser == null
      ) {
        let cUser = {};
        ApiCalls.getContactDetails(appConfig.host, sPhone, appConfig.source, appConfig.bCacheUsers, appConfig.company, assessor.email, true)
              .then(resp => resp.json())
              .then(resp => {
                cUser = {...resp, ...{
                  client_email: resp.email,
                  client_familiarname: resp.client_name,
                  client_lastname: resp.client_lastname,
                  client_name: resp.client_name,
                  displayname: resp.client_lastname ? `${resp.client_name} ${resp.client_lastname}` : resp.client_name,
                  email: resp.email,
                  country: resp.country,
                  course_id: resp.idcurso ?? 0,
                  course_name: resp.oppo_name ?? '',
                  phone: sPhone
                }}
                newContactSelected(cUser);
              }).catch(err =>  {
                if (typeof errorMessageThrown.current[7] == 'undefined' || (typeof errorMessageThrown.current[7] != 'undefined' && errorMessageThrown.current[7] == false) ) {
                  setErrorMessageArr(errMsgArr => [
                    errMsgArr,
                    {
                      key: "phone",
                      code: 7,
                      message: 'No tenemos ningún dato sobre el teléfono que buscas.',
                      value: null
                    }
                  ]);
                  errorMessageThrown.current[7] = true
                }
              });
      } else if (
        contactList.length > 0 // El usuario tiene agenda de contactos
        && !foundContact.current // No hemos encontrado el teléfono
        && autoSelectConversation.ulUser == null // No está en la agenda de conctactos
        && autoSelectConversation.clUser == null // No está en la lista de conversaciones
      ){
        if (typeof appConfig.clientConfig != 'undefined' && Object.keys(appConfig.clientConfig).length) {
          // Si en el proyecto de ERP tenemos la propiedad has_cansendcontactssamesource y es true pueden iniciar una conversación si otro usuario del mismo source ya tiene una conversación iniciada previamente
          let __filterConfigSource = appConfig.clientConfig?.sources?.find(source => source.source.toUpperCase() == appConfig.source.toUpperCase());
          if (typeof __filterConfigSource?.has_cansendcontactssamesource != 'undefined' && __filterConfigSource.has_cansendcontactssamesource == true) {
            let cUser = {};
            ApiCalls.getContactDetails(appConfig.host, sPhone, appConfig.source, appConfig.bCacheUsers, appConfig.company, assessor.email, true)
                .then(resp => resp.json())
                .then(resp => {
                  cUser = {...resp, ...{
                    client_email: resp.email,
                    client_familiarname: resp.client_name,
                    client_lastname: resp.client_lastname,
                    client_name: resp.client_name,
                    displayname: resp.client_lastname ? `${resp.client_name} ${resp.client_lastname}` : resp.client_name,
                    email: resp.email,
                    country: resp.country,
                    course_id: resp.idcurso ?? 0,
                    course_name: resp.oppo_name ?? '',
                    phone: sPhone
                  }}
                  newContactSelected(cUser);
                }).catch(err =>  {
                  console.log('err', err)
                  if (typeof errorMessageThrown.current[7] == 'undefined' || (typeof errorMessageThrown.current[7] != 'undefined' && errorMessageThrown.current[7] == false) ) {
                    setErrorMessageArr(errMsgArr => [
                      errMsgArr,
                      {
                        key: "phone",
                        code: 7,
                        message: 'No tenemos ningún dato sobre el teléfono que buscas.',
                        value: null
                      }
                    ]);
                    errorMessageThrown.current[7] = true
                  }
                });
          } else {
            if (typeof errorMessageThrown.current[8] == 'undefined' || (typeof errorMessageThrown.current[8] != 'undefined' && errorMessageThrown.current[8] == false) ) {
              setErrorMessageArr(errMsgArr => [
                errMsgArr,
                {
                  key: "phone",
                  code: 8,
                  message: 'El teléfono que buscas no tiene una conversación iniciada ni está en tu agenda de contactos.',
                  value: null
                }
              ]);
              errorMessageThrown.current[8] = true
            }
          }
        }
      }
    }    
  }, [userList, contactList, getMessagesByEmailCalled, appConfig.clientConfig]);

  const theme = createTheme({
    palette: {
      default: {
        main: "#4fc3f7"
      },
      primary: {
        main: appConfig.darkTheme ? "#fff" : "#000"
      },
      secondary: {
        main: "#fff"
      }
    },
    overrides: {
      MuiCheckbox: {
        colorSecondary: {
          "&$checked": {
            color: "#000"
          }
        }
      }
    }
  });

  const selectConversation = async (phone, minimized, viewUserList, calledFromInit = false, exception = false, oProps={})  => {
      if (phone) {
        setSkipSaveAppConfig(true)
        setAppConfig(appConfig => {
          return {
            ...appConfig,
            minimized: minimized,
            selectedIndex: phone,
          };
        });
        setControlConfig(controlConfig => {
          return {
            ...controlConfig,
            viewContactList: false,
            viewUserList: viewUserList,
            showLoaderContactList: false
          };
        });
        let ulUser = userList.find(item => item.phone == phone);
        if(typeof ulUser != 'undefined') {
          userSelected(ulUser, calledFromInit);
        } else {
          let clUser = contactList.find(item => item.phone == phone);
          if(typeof clUser != 'undefined') {
            newContactSelected(clUser);
          } else {
            if(calledFromInit) {
              sClientEmail = oProps.sClientEmail;
              sClientName = oProps.client_name;
              sClientLastName = oProps.client_lastname;
            }
            let cUser = {
              assessor_email: assessor.email,
              assessor_first_name: assessor.name,
              assessor_last_name: assessor.lastName,
              client_email: sClientEmail,
              client_familiarname: sClientName,
              client_lastname: sClientLastName,
              client_name: sClientName,
              displayname: sClientLastName ? `${sClientName} ${sClientLastName}` : sClientName,
              email: sClientEmail,
              country: sCountry,
              course_id: sCourseId ?? 0,
              course_name: sCourseName ?? '',
              date_modified: null,
              hasconversation: false,
              phone: phone,
              company: appConfig.company,
              unit: appConfig.unit,
              source: appConfig.source,
              key: `${phone}:${appConfig.source}:${appConfig.company}`,
              isNewChat: true
            }
            ApiCalls.getContactDetails(appConfig.host, phone, appConfig.source, appConfig.bCacheUsers, appConfig.company, assessor.email, exception)
              .then(resp => resp.json())
              .then(resp => {
                cUser = {...cUser, ...{
                  client_email: resp.email,
                  client_familiarname: resp.client_name,
                  client_lastname: resp.client_lastname,
                  client_name: resp.client_name,
                  displayname: resp.client_lastname ? `${resp.client_name} ${resp.client_lastname}` : resp.client_name,
                  email: resp.email,
                  country: resp.country,
                  course_id: resp.idcurso ?? 0,
                  course_name: resp.oppo_name ?? ''
                }}
                userSelected(cUser, calledFromInit);
              })
              .catch(err =>  {
                if (appConfig.verbose) {
                  console.log('getContactDetails', err)
                }
                if (typeof errorMessageThrown.current[7] == 'undefined' || (typeof errorMessageThrown.current[7] != 'undefined' && errorMessageThrown.current[7] == false) ) {
                  setErrorMessageArr(errMsgArr => [
                    errMsgArr,
                    {
                      key: "phone",
                      code: 7,
                      message: 'No tenemos ningún dato sobre el teléfono que buscas.',
                      value: null
                    }
                  ]);
                  errorMessageThrown.current[7] = true
                }
              });
          }
        }
      }
  }
  window.selectConversation = selectConversation;


  const throwAppError = async (err) => {
    setErrorMessageArr(errMsgArr => [
      errMsgArr,
      {
        key: err.key,
        code: err.code,
        message: err.message,
        value: err.value ?? null
      }
    ]);
    errorMessageThrown.current[err.num] = true;
  }
  window.throwAppError = throwAppError;

  const checkAsessorEmail = (email) => { 
    return assessor.email == email;
  }

  const verbose = () => {
    setAppConfig((oAppConfig) => {
      return {
        ...oAppConfig,
        verbose: !oAppConfig.verbose
      }
    })
  }
  window.verbose = verbose;

  window.checkAsessorEmail = checkAsessorEmail

  return (
    <AppContext.Provider
      value={{
        client,
        setClient,
        assessor,
        setAssessor,
        delegates,
        setDelegates,
        appConfig,
        setAppConfig,
        userSelected,
        newContactSelected,
        newContactGroupSelected,
        saveSettings,
        getClientConfig,
        controlConfig,
        setControlConfig,
        userList,
        setUserList,
        filteredUserList,
        setFilteredUserList,
        contactList,
        setContactList,
        contactAssessorList,
        setContactAssessorList,
        message,
        setMessage,
        messages,
        setMessages,
        messageSchedule,
        setMessageSchedule,
        searchMessages,
        setSearchMessages,
        messagesSchedule,
        setMessagesSchedule,
        debugApp,
        newChatItem,
        setNewChatItem,
        twilioVideo,
        setTwilioVideo,
        companyTags,
        setCompanyTags,
        updateComponent,
        setUpdateComponent,
        lastWebComponentVersion,
        setLastWebComponentVersion,
        skipSaveAppConfig, 
        setSkipSaveAppConfig,
        selectedTag, 
        setSelectedTag,
        initNewMQTTWS, 
        setInitNewMQTTWS,
        MQTTConnectionsList, 
        setMQTTConnectionsList,
        stopDelegating, 
        setStopDelegating,
        needUpdateUserLayout, 
        setNeedUpdateUserLayout,
        online, 
        setOnline,
        getMessagesByEmailCalled, 
        setGetMessagesByEmailCalled,
        defaultMessages, 
        setDefaultMessages,
        inputChatText,
        setInputChatText,
        selectedDefaultMessage,
        setSelectedDefaultMessage,
        // defaultCompanyMessagesfilters,
        // setDefaultCompanyMessagesfilters,
        errorMessageArr,
        setErrorMessageArr
      }}
    >
      {!is_electron() || appConfig.loggedIn ? (
        <ThemeProvider theme={theme}>
          <Chat
            chatProps={{
              bDeveloper,
              bAlwaysRefresh,
              onLoadError: errorMessageArr
            }}
          />
        </ThemeProvider>
      ) : (
        <Login />
      )}
    </AppContext.Provider>
  );
};

App.propTypes = {
  appProps: PropTypes.shape({
    sPhone: PropTypes.string,
    sAssessorName: PropTypes.string,
    sAssessorLastName: PropTypes.string,
    sAssessorEmail: PropTypes.string,
    sSource: PropTypes.string,
    sClientName: PropTypes.string,
    sClientLastName: PropTypes.string,
    sClientEmail: PropTypes.string,
    sCountry: PropTypes.string,
    sCourseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sCourseName: PropTypes.string,
    bFilterSource: PropTypes.bool,
    sLanguage: PropTypes.string,
    bDeveloper: PropTypes.bool,
    bShowUserList: PropTypes.bool,
    bAlwaysRefresh: PropTypes.bool,
    bMinimized: PropTypes.bool,
    sCompany: PropTypes.string,
    sUnit: PropTypes.string,
    nWidth: PropTypes.string,
    nHeight: PropTypes.string,
    nSize: PropTypes.number,
    bDarkTheme: PropTypes.bool,
    aDefaultMessages: PropTypes.array,
    aoSources: PropTypes.array,
    sDisplayName: PropTypes.string,
    bGroup: PropTypes.bool,
    sEncryptionKey: PropTypes.string,
    sHost: PropTypes.string,
    bUseWs: PropTypes.bool,
    bOpenDialogGlobalSearch: PropTypes.bool,
    sVersion: PropTypes.string,
    bShowCloseButton: PropTypes.bool,
    aousetags: PropTypes.bool,
    aoExpandedTagsMenu: PropTypes.bool,
    bVerbose: PropTypes.bool,
    oNotifications: PropTypes.bool,
    sDefaultCompanyMessagesfilters: PropTypes.array
  })
};

export default App;
