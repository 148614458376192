const Helper = {
  constants: {
    advisers: "advisers",
    conditionsSources: {
      has_audio_calls: "has_audio_calls",
      has_video_calls: "has_video_calls",
      has_group_messages: "has_group_messages"
    },
    contactListSkeleton: {
      minItems: 3,
      maxItems: 5,
      minHeight: 20,
      maxHeight: 100
    },
    customers: "customers",
    defaultSource: "",
    https: "https://",
    messageSkeleton: {
      minItems: 2,
      maxItems: 4,
      nItems: 3,
      minHeight: 20,
      maxHeight: 150
    },
    messageStatus: {
      i_am_an_experiment: "i_am_an_experiment",
      failed: "failed",
      queued: "queued",
      sending: "sending",
      delivered: "delivered",
      acepted: "acepted",
      undelivered: "undelivered",
      send: "send",
      sent: "sent",
      received: "received",
      read: "read",
      deleted: "deleted",
      requestPermissions: "request_permissions",
      marketing_limit: "marketing_limit"
    },
    mimeTypeApplication: [
      "application/pdf",
      "text/vcard",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.ms-powerpoint",
      "application/vnd.ms-excel",
      "application/msword",
      "application/vnd.apple.pkpass",
      "application/vcard",
      "application/pdf"
    ],
    mimeTypeAudio: [
      "audio/ogg; codecs=opus",
      "audio/aac",
      "audio/amr",
      "audio/amr-nb",
      "audio/webm",
      "audio/ac3",
      "audio/3gpp2",
      "audio/3gpp",
      "audio/vnd.wave",
      "audio/vnd.rn-realaudio",
      "audio/ogg",
      "audio/mpeg",
      "audio/mp4",
      "audio/basic",
      "audio/L24"
    ],
    mimeTypeImage: ["image/bmp", "image/tiff", "image/jpeg"],
    mimeTypeText: [
      "text/calendar",
      "text/calendar",
      "text/calendar",
      "text/csv",
      "text/x-vcard",
      "text/vcard"
    ],
    mimeTypeVideo: [
      "video/H264",
      "video/H263-2000",
      "video/H263-1998",
      "video/H263",
      "video/H261",
      "video/mp4"
    ],
	// https://developers.facebook.com/docs/whatsapp/cloud-api/reference/media/#supported-media-types
	facebookSupportedMimes: [
		"audio/aac",
		"audio/amr",
		"audio/mpeg",
		"audio/mp4",
		"audio/ogg",
		"text/plain",
		"application/vnd.ms-excel",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"application/msword",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"application/vnd.ms-powerpoint",
		"application/vnd.openxmlformats-officedocument.presentationml.presentation",
		"application/pdf",
		"image/jpeg",
		"image/png",
		"image/webp",
		"video/3gpp",
		"video/mp4"
	],
    newChat: "newChat",
    newGroup: "newGroup",
    notificationDuration: 2000,
    notificationSenderId: "1019683924942",
    refreshInterval: 15000,
    sFieldGroup: "displayname",
    tabAll: "all",
    tabUnread: "unread",
    userListSkeleton: {
      minItems: 3,
      maxItems: 5,
      minHeight: 20,
      maxHeight: 100
    },
    sentimentTag: {
      positive: {
        key: "POS",
        colour: "#71AD47"
      },
      neutral: {
        key: "NEU",
        colour: "#FFFFFF"
      },
      negative: {
        key: "NEG",
        colour: "#C00000"
      }
    },
    url: {
      releaseNotes:
        "https://wiki.universalmessenger.net/books/historial-de-versiones/page/historial-de-versiones-del-componente-web",
      userPermissions:
        "https://wiki.universalmessenger.net/books/tips-tricks/page/aceptacion-de-permiso-por-parte-del-cliente-en-whatsapp",
      userManual:
        "https://wiki.universalmessenger.net/books/manuales/page/manual-componente-web"
    },
    websocketRetries: 2
  },

  getSourcesFromMessages: aoResponseMessages => {
    let asSources = [];
    let oSourcesById = {};

    aoResponseMessages?.forEach(oMessage => {
      if (oMessage.source && oMessage.source.length > 0) {
        let sSource = oMessage.source.split(",")[0];
        if (!oSourcesById[sSource]) {
          oSourcesById[sSource] = true;
          asSources.push(sSource);
        }
      }
    });
    return asSources;
  },

  getRandomInt: (nMin, nMax) => {
    return Math.floor(Math.random() * (nMax - nMin)) + nMin;
  },

  filterArraySearchString: (aoItems, sSearchString, aSField, tabSelection) => {
    let aoItemsFiltered = [];
    const normalizeSearchString = sSearchString
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();

    if (aoItems && Array.isArray(aoItems)) {
      aoItemsFiltered = aoItems.filter(oItem => {
        let bReturn = false;

        aSField.forEach(sField => {
          let bRetIteration = oItem
            ? oItem[oItem.group ? Helper.constants.sFieldGroup : sField]
                ?.normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toUpperCase()
                .indexOf(normalizeSearchString) > -1 || !sSearchString
            : false;
          bReturn = bReturn || bRetIteration;
        });

        return bReturn;
      });

      if (tabSelection) {
        aoItemsFiltered = aoItemsFiltered.filter(oItem => {
          if (tabSelection === Helper.constants.tabUnread) {
            return oItem.unread > 0 || oItem.markasunread;
          } else if (tabSelection === Helper.constants.tabAll) {
            return true;
          } else if (
            tabSelection !== Helper.constants.tabUnread &&
            tabSelection !== Helper.constants.tabAll
          ) {
            return oItem.source === tabSelection;
          }
        });
      }
    }
    return aoItemsFiltered;
  },

  isDateLabelNeeded: (dMessageDate, dLastMessageDate) => {
    return (
      !dLastMessageDate ||
      dMessageDate.getDate() !== dLastMessageDate.getDate() ||
      dMessageDate.getMonth() !== dLastMessageDate.getMonth() ||
      dMessageDate.getFullYear() !== dLastMessageDate.getFullYear()
    );
  },

  isDateMonthLabelNeeded: (date, lastDate) => {
    return !lastDate || date.getMonth() !== lastDate.getMonth();
  },

  addZeroAtBeginning: sValue => {
    if (!isNaN(sValue) && sValue < 10) {
      sValue = "0" + sValue;
    }

    return sValue;
  },

  parseDate: sDate => {
    let date = new Date(sDate);
    return (
      Helper.addZeroAtBeginning(date.getDate()) +
      "-" +
      Helper.addZeroAtBeginning(date.getMonth() + 1) +
      "-" +
      date.getFullYear()
    );
  },

  parseHour: sDate => {
    let date = new Date(sDate);
    return (
      Helper.addZeroAtBeginning(date.getHours()) +
      ":" +
      Helper.addZeroAtBeginning(date.getMinutes())
    );
  },

  parseMonth: sDate => {
    let date = new Date(sDate);
    let num = date.getMonth() + 1;
    let month = new Date();
    if (0 < num && num <= 12) {
      month.setMonth(num - 1);
      let sMonth = new Intl.DateTimeFormat("es-ES", { month: "long" }).format(month);
      return sMonth.charAt(0).toUpperCase() + sMonth.slice(1);
    } else {
      return null;
    }
  },

  removeCallDelegates: (clientConfig, source) => {
    if (
      Object.keys(clientConfig).length !== 0 &&
      clientConfig.sources?.length > 0 &&
      source !== "" &&
      source !== undefined
    ) {
      const oSource = clientConfig.sources.find(oSource => oSource.source === source.split(",")[0]);
      if (typeof oSource?.has_substitutes != 'undefined') {
        return oSource.has_substitutes;
      } else {
        console.log('No existe el proyecto. Contacta con UBM.')
        return false;
      }
    } else {
      return false;
    }
  },

  isSingleEmoji: sText => {
    const emoticonRegex =
      /^[\u{1F600}-\u{1F64F}\u{1F910}-\u{1F917}\u{1F919}-\u{1F970}\u{1F973}-\u{1F976}\u{1F97A}\u{1F97C}\u{1F97D}\u{1F9A0}-\u{1F9A2}\u{1F9B0}-\u{1F9B3}\u{1F9B5}\u{1F9B6}\u{1F9BA}-\u{1F9BF}\u{1F9C1}\u{1F9C2}\u{1F9D0}-\u{1F9E6}\u{1F9E8}-\u{1F9FF}][\u{FE0F}]?$/u;
    const oSingleEmojiRegExp = new RegExp(emoticonRegex);

    let bLength = sText.trim().length === 2;
    let bSingleEmoji = oSingleEmojiRegExp.test(sText);
    return bLength && bSingleEmoji;
  },

  filterUsersByTag: (userList, tag) => {
    let newFilteredUserList = [...userList];
    if (tag == null || typeof tag == 'undefined') {
      tag = {}
    }
    if (tag.key == null || typeof tag.key == 'undefined') {
      tag.key = 'all';
    }
    if (tag.key !== "all") {
      newFilteredUserList = newFilteredUserList.filter(oConversation => {
        let bRet = false;
        if (oConversation.tags) {
          oConversation.tags.company_tags.forEach(sTag => {
            if (sTag === tag.key) {
              bRet = true;
            }
          });

          oConversation.tags.user_tags.forEach(sTag => {
            if (sTag === tag.key) {
              bRet = true;
            }
          });
        }

        return bRet;
      });
    }
    return newFilteredUserList;
  },

  cleanPhone: (phone) => {
    var numb = phone.match(/\d/g);
    return parseInt(numb.join(""));
  }
};

export default Helper;
